import loadable from '@loadable/component';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getPopularCategoryPodcasts } from 'state/Podcast/selectors';
import type { Podcast } from 'state/Podcast/types';
import type { State } from 'state/types';

const PopularPodcasts = loadable(() => import('./PopularPodcasts'));

export default connect(
  createStructuredSelector<State, { popularCategoryPodcasts: Array<Podcast> }>({
    popularCategoryPodcasts: getPopularCategoryPodcasts,
  }),
)(PopularPodcasts);
