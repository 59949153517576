import * as React from 'react';
import H4 from 'primitives/Typography/Headings/H4';
import HostsAndCreators from 'views/Podcast/HostsAndCreators';
import PopularPodcasts from 'views/Podcast/PopularPodcasts';
import RailItems from 'views/Podcast/primitives/RailItems';
import RailLink from './primitives/RailLink';
import RailSection from 'views/Podcast/primitives/RailSection';
import ShouldShow from 'components/ShouldShow';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { CustomLink, SocialMediaLink } from 'state/Podcast/types';
import {
  Facebook,
  Instagram,
  NewWindowLink,
  Pinterest,
  Snapchat,
  TikTok,
  X,
  YouTube,
} from 'styles/icons';

export type Props = {
  customLinks?: Array<CustomLink>;
  isAbTest?: boolean;
  seedId: number;
  socialMediaLinks?: Array<SocialMediaLink>;
};

const ICON_MAP = {
  facebook_profile_link: Facebook,
  flickr_profile_link: 'flickr', // soon to be added field
  instagram_username: Instagram,
  link: NewWindowLink,
  pintrest_username: Pinterest, // yes, 'pintrest' key is misspelled... it comes from AMP that way :shrug:
  reddit_profile_link: 'reddit', // soon to be added field
  snapchat_link: Snapchat,
  tiktok_username: TikTok,
  tumblr_profile_link: 'tumblr', // soon to be added field
  twitter_username: X,
  youtube_profile_link: YouTube,
};

function PodcastSideRail({
  customLinks = [],
  isAbTest = false,
  seedId,
  socialMediaLinks = [],
}: Props) {
  const translate = useTranslate();

  return (
    <ShouldShow shouldShow={!!seedId}>
      <ShouldShow shouldShow={socialMediaLinks.length > 0}>
        <RailSection>
          <H4>{translate('Follow Us On')}</H4>

          <RailItems data-test="social-media-links" horizontal>
            {socialMediaLinks.map(({ link, name }) => {
              const Icon = (ICON_MAP[name as keyof typeof ICON_MAP] ||
                NewWindowLink) as React.ComponentType<
                React.HTMLAttributes<SVGElement>
              >;
              return (
                <RailLink horizontal key={`${name}-${link}`} to={link}>
                  <Icon
                    data-test={`${name}-icon`}
                    style={{ height: '2.4rem', width: '2.4rem' }}
                  />
                </RailLink>
              );
            })}
          </RailItems>
        </RailSection>
      </ShouldShow>
      <HostsAndCreators />

      <ShouldShow shouldShow={customLinks.length > 0}>
        <RailSection>
          <H4>{translate('Show Links')}</H4>

          <RailItems data-test="custom-links">
            {customLinks.map(({ link, navigationIcon, title }) => {
              const Icon = (ICON_MAP[navigationIcon as keyof typeof ICON_MAP] ||
                NewWindowLink) as React.ComponentType<
                React.HTMLAttributes<SVGElement>
              >;
              return (
                <RailLink key={`${title}-${link}`} to={link}>
                  <Icon
                    data-test={`${navigationIcon}-${link}-icon`}
                    style={{
                      height: '1.2rem',
                      marginRight: '1rem',
                      width: '1.2rem',
                    }}
                  />
                  {title}
                </RailLink>
              );
            })}
          </RailItems>
        </RailSection>
      </ShouldShow>
      {!isAbTest && (
        <PopularPodcasts itemSelectedLocation="podcast_profile|popular_podcasts|right_rail" />
      )}
    </ShouldShow>
  );
}

export default PodcastSideRail;
